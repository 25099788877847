<template>
  <div>
    <b-row>
      <b-col class="my-2">
        <b-button variant="primary" :to="`/users/add`" class="float-right">
          <feather-icon icon="PlusIcon" class="mr-50" />
          <span  class="align-middle">{{$t("Labels.Add")}}</span>
        </b-button>
      </b-col>
    </b-row>
    <div id="data-list-list-view" class="data-list-container mt-2 px-">
      <b-table hover :fields="fields" :items="responseUser" :responsive="true" tbody-tr-class="shadow-sm" table-variant="light">
          <template #cell(first_namelast_name)="data">
            <listItem :value="data.item.first_namelast_name" :description="$t('Labels.Name')" img="contact"/>
          </template>
          <template #cell(email)="data">
            <listItem :value="data.item.email" :description="$t('Email')" img="email"/>
          </template>
          <template #cell(tracker)="data">
            <listItem :value="data.item.tracker" :description="$t('Labels.Tracker')" img="alias"/>
          </template>
          <template #cell(serie)="data">
            <listItem :value="data.item.serie" :description="$t('Labels.Serie')" img="alias"/>
          </template>
          <template #cell(uuid)="data">
            <b-button variant="danger" class="btn-icon rounded-circle btn-option" @click="confirmDelete(data.value)" :title="$t('Labels.Delete')" v-b-tooltip.hover="{ variant: 'danger', placement: 'left' }">
              <feather-icon icon="TrashIcon" :width="18" :height="18"/>
            </b-button>
            <b-button variant="primary" class="btn-icon rounded-circle btn-option" :to="`/users/update/${data.value}`" :title="$t('Labels.Edit')" v-b-tooltip.hover="{ variant: 'primary', placement: 'right' }">
              <feather-icon icon="EditIcon" :width="18" :height="18"/>
            </b-button>
          </template>
      </b-table>
      <div v-if="responseUser.length == 0" class="noData">
        {{$t('Messages.NoData')}}
      </div>
    </div>
  </div>
</template>

<script>
import request from '@/libs/request/index'
import listItem from '@core/spore-components/listItem/listItem.vue'
import { empty } from '@/libs/tools/helpers'
import {
  BButton,
  BRow,
  BCol,
  BTable
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    listItem,
    BTable
  },
  data () {
    return {
      responseUser: [],
      fields: [
        { key: 'first_namelast_name', label: this.$t('Labels.Name'), sortable: true },
        { key: 'email', label: this.$t('Email'), sortable: true },
        { key: 'tracker', label: this.$t('Tracker'), sortable: true },
        { key: 'serie', label: this.$t('Labels.Serie'), sortable: true },
        { key: 'uuid', label: '' }
      ]
    }
  },

  methods: {
    async getResponse () {
      const params = {
        url: 'tracker_users/users',
        method: 'GET'
      }
      await request(params).then(response => {
        for (let index = 0; index < response.data.data.length; index++) {
          if (response.data.data[index]) {
            this.responseUser.push({
              first_namelast_name: (response.data.data[index].first_name ? response.data.data[index].first_name + ' ' + response.data.data[index].last_name : ''),
              email: (response.data.data[index].email ? response.data.data[index].email : ''),
              tracker: (!empty(response.data.data[index].tracker_dual_data) ? response.data.data[index].tracker_dual_data[0].name : this.$t('')),
              serie: (!empty(response.data.data[index].tracker_dual_data) ? response.data.data[index].tracker_dual_data[0].serie : this.$t('Labels.NotAssigned')),
              uuid: (response.data.data[index].uuid ? response.data.data[index].uuid : '')
            })
          }
        }
      }).catch(() => {
        this.responseUser = []
      })
    },
    confirmDelete (uuid) {
      this.$swal({
        title: this.$t('Messages.ConfirmDeleteUser'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Messages.Yes'),
        cancelButtonText: this.$t('Messages.Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(async result => {
        if (result.value) {
          const params = {
            url: `tracker_users/${uuid}`,
            method: 'DELETE'
          }
          await request(params).then(response => {
            this.getResponse()
          })
        }
      })
    }
  },

  created () {
    this.getResponse()
  }
}
</script>
<style lang="scss" scoped>
  @import "@/assets/scss/pages/Shares.scss";
</style>
